import { render, staticRenderFns } from "./DepositList.vue?vue&type=template&id=5706a8ad&scoped=true"
import script from "./DepositList.vue?vue&type=script&lang=js"
export * from "./DepositList.vue?vue&type=script&lang=js"
import style0 from "./DepositList.vue?vue&type=style&index=0&id=5706a8ad&prod&scoped=true&lang=css"
import style1 from "./DepositList.vue?vue&type=style&index=1&id=5706a8ad&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5706a8ad",
  null
  
)

export default component.exports